<template>
  <div>
    <div class="center" v-if="!isSoldOut">
      <img src="@/assets/pc/tron.png" style="width: 43px; margin-right: 15px" />
      <img src="@/assets/pc/100 TRX.png" style="width: 75px" />
    </div>

    <!-- <div v-if="!isSoldOut" class="center" style="justify-content: flex-start; margin: 10px 0">
      <img src="@/assets/pc/trx.png" style="width: 30px; margin-right: 10px" />
      <p style="white-space: nowrap">
        <span style="font-size: 20px">100 TRX </span
        ><span style="font-size: 14px; font-style: italic; color: #9e9c9b">per unit</span>
      </p>
    </div> -->

    <div class="mint-box" v-if="!isSoldOut">
      <!-- total available -->

      <!-- num-select -->
      <div class="mint-select center">
        <div class="edit-icon center" :class="{ disabled: minusDisabled }" @click="minusMintNum">&#8722;</div>
        <div class="edit-input">{{ mintNum }}</div>
        <div class="edit-icon center" :class="{ disabled: addDisabled }" @click="addMintNum">&#43;</div>
      </div>
      <!-- confirm -->
      <div>
        <div class="mint-confirm center" v-if="!isConnected && !isConnecting" @click="connectWallet">
          <img style="width: 138px" src="@/assets/pc/connect.png" />
        </div>
        <div class="mint-confirm center disabled" v-else-if="isConnecting">
          <img style="width: 108px" src="@/assets/pc/coning.png" />
        </div>
        <div class="mint-confirm center" :class="{ disabled: !canMint }" v-else-if="!isMinting" @click="triggerMint">
          <img style="width: 44px" src="@/assets/pc/mint.png" />
        </div>
        <div class="mint-confirm center disabled" v-else>
          <img style="width: 87px" src="@/assets/pc/minting.png" />
        </div>
      </div>
    </div>

    <div class="mint-box-soldout center" style="flex-direction: column" v-else>
      <p style="font-size: 30px; font-weight: bold">SOLD OUT</p>
      <!-- <p style="font-size: 14px; font-weight: bold; margin-top: 5px">BUY ON APENET</p> -->
      <!-- <img src="@/assets/pc/sold-out-icon.png" id="soldout-icon" /> -->
    </div>
  </div>
</template>
<script>
import { walletMixin } from '../mixin.js';
export default {
  name: 'stage23',
  mixins: [walletMixin],
  props: {
    countdownArr: [],
    stage: Number,
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.countdown-box {
  width: 39px;
  height: 29px;
  background: #cd4743;
  border-radius: 3px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}
.countdown-divider {
  color: #cd4743;
  margin: 0 5px;
}
.mint-box {
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
  color: #000;
  width: 100%;
}
.mint-box-soldout {
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
  color: #000;
  width: 100%;
  position: relative;
  #soldout-icon {
    width: 60px;
    position: absolute;
    bottom: 10px;
    left: 100px;
  }
}
.mint-select {
  // width: 100px;
  margin: 12px auto;
  .edit-icon {
    // width: 18px;
    // height: 18px;
    font-size: 50px;
    // border-radius: 100%;
    // color: #fff;
    cursor: pointer;
    padding-bottom: 3px;
    font-weight: bold;
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  .edit-input {
    font-size: 50px;
    font-weight: bold;
    color: #000;
    width: 60px;
    text-align: center;
  }
}
.mint-confirm {
  width: 176px;
  height: 52px;
  border-radius: 5px;
  background-color: #cd4743;
  color: #fff;
  cursor: pointer;
  margin: 0 auto;
  background: url('~@/assets/pc/btn.png') no-repeat;
  background-size: contain;
  padding-bottom: 5px;
  &.disabled {
    opacity: 0.5;
  }
}
</style>
